
import Vue from "vue";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IStationEquipment } from "@common/station";
import _ from "lodash";
import { IIngredient } from "@common/ingredient";

export default Vue.extend({
	name: "EquipmentTab",
	props: {
		stationId: String,
	},
	data () {
		const snackbar = {
			show: false,
			text: "",
			color: "primary",
		};

		const ingredientSelectItems: {
			text: string;
			value: string;
		}[] = [];

		return {
			rawData: [] as IStationEquipment[],
			wholeTable: [] as (IStationEquipment & {ingredientName?: string})[],
			snackbar,
			filters: {
				ingredients: [] as string[]
			},
			autoCompleteChipsLimit: 10,
			loading: false,
			loseDataModalVisible: false,
			loseDataModalData: 1,
			rules: {
				positiveNumber: (v: string) => (!isNaN(parseFloat(v)) && parseFloat(v) >= 0) || "Value must be a positive number"
			},
			ingredientSelectItems,
		};
	},
	created: async function () {
		await this.fetchIngredientsOnlyUsed();

		this.filters.ingredients = [this.ingredientSelectItems[0].value];

		this.fetchData();
	},
	methods: {
		async fetchIngredientsOnlyUsed () {
			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/ingredient/onlyUsed`,
				};

				const res: AxiosResponse<IServerRES<IIngredient[]>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					for (const item of res.data.payload) {
						this.ingredientSelectItems.push({
							text: `(${item.id}) ${item.name}`,
							value: item.id
						});
					}
				}
			} catch (err) {
				console.error(err);
			}
		},
		async fetchData () {
			this.loading = true;

			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/station/equipment/${this.stationId}`,
				};

				const res: AxiosResponse<IServerRES<IStationEquipment[]>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.rawData = res.data.payload;
				}
			} catch (err) {
				console.error(err);
			}

			this.dbDataToTable();

			setTimeout(() => {
				this.loading = false;
			}, 300);
		},
		dbDataToTable () {
			this.wholeTable = _.map(
				_.filter(this.rawData, entry => !!_.find(this.filters.ingredients, p => p === entry.ingredientId)),
				item => ({
					...item,
					ingredientName: _.find(this.ingredientSelectItems, p => p.value === item.ingredientId)?.text
				})
			);
		},
		getChangesInData () {
			const final: IStationEquipment[] = [];
			_.forEach(this.wholeTable, item => {
				const dbItem = _.find(this.rawData, entry => entry.ingredientId === item.ingredientId);
				if (dbItem) {
					if (!_.isEqual(dbItem.batchesPerHour, item.batchesPerHour) || !_.isEqual(dbItem.capacityPerBatch, item.capacityPerBatch)) {
						final.push({
							stationId: this.stationId,
							ingredientId: item.ingredientId,
							batchesPerHour: item.batchesPerHour,
							capacityPerBatch: item.capacityPerBatch
						});
					}
				}
			});
			return final;
		},
		async save () {
			this.loading = true;

			const data: IStationEquipment[] = this.getChangesInData();

			try {
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/station/equipment`,
					data
				};

				const res: AxiosResponse<IServerRES<boolean>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.snackbar = {
						show: true,
						text: "Equipment limits saved successfully!",
						color: "success",
					};
				} else {
					this.snackbar = {
						show: true,
						text: "Failed to save equipment limits!",
						color: "error",
					};
				}
			} catch (err) {
				console.error(err);

				this.snackbar = {
					show: true,
					text: "Failed to save equipment limits!",
					color: "error",
				};
			}

			setTimeout(() => {
				this.loading = false;
			}, 300);
		},
		toggleSelectAllIngredients () {
			if (this.filters.ingredients.length === this.ingredientSelectItems.length) {
				this.filters.ingredients = [];
			}	else {
				for (const item of this.ingredientSelectItems) {
					if (this.filters.ingredients.indexOf(item.value) === -1) {
						this.filters.ingredients.push(item.value);
					}
				}
			}
		},
	},
	computed: {
		headers () {
			return [
				{ text: "Ingredient", align: "start" },
				{ text: "Batches per hour", align: "start" },
				{ text: "Capacity per batch", align: "start" }
			];
		},
		icon () {
			if (this.filters.ingredients.length === this.ingredientSelectItems.length) return "mdi-close-box";
			if (this.filters.ingredients.length !== this.ingredientSelectItems.length && this.filters.ingredients.length !== 0) return "mdi-minus-box";
			return "mdi-checkbox-blank-outline";
		},
		hoursOfDay () {
			return _.range(0, 24);
		}
	},
	watch: {
		stationId: function () {
			this.fetchData();
		}
	}
});
